.rdp {
  margin: 0;
}

.rdp-nav_icon {
  color: #652cb3;
}

.rdp-day_selected,
.rdp-day_selected:hover {
  background-color: #652cb3;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: rgba(101, 44, 179, 0.1);
}

.markdown-container ul {
  list-style: inside;
}

.markdown-container li {
  margin-left: 16px;
}
