.gm-style-mtc,
.gm-svpc,
.gm-fullscreen-control {
  display: none !important;
}

.gmnoprint {
  right: -2px !important;
  left: unset !important;
  bottom: 4px !important;
  top: unset !important;
}

.gmnoprint > div:first-of-type {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  box-shadow: none !important;
  width: fit-content !important;
  height: fit-content !important;
}

.gmnoprint > div:first-of-type > div {
  display: none;
}

.gmnoprint > div:first-of-type > button {
  width: 2rem !important;
  height: 2rem !important;
  background-color: white !important;
  border-radius: 50% !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.gm-style-cc {
  position: unset !important;
}

@media (min-width: 768px) {
  .gmnoprint > div:first-of-type > button {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  .gmnoprint > div:first-of-type {
    gap: 0.5rem;
  }

  .gmnoprint {
    right: 0 !important;
    bottom: 12px !important;
  }
}

@media (min-width: 1024px) {
  .gmnoprint > div:first-of-type > button {
    width: 3.125rem !important;
    height: 3.125rem !important;
  }

  .gmnoprint > div:first-of-type {
    gap: 0.75rem;
  }
}
