*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #f4f4f4;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #aaa;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2a75c780;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2a75c780;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible {
  visibility: visible;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-left-4 {
  left: -1rem;
}

.-right-12 {
  right: -3rem;
}

.-right-4 {
  right: -1rem;
}

.-top-2 {
  top: -.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-5 {
  bottom: 1.25rem;
}

.left-2 {
  left: .5rem;
}

.left-2\.5 {
  left: .625rem;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-4 {
  right: 1rem;
}

.right-\[-34px\] {
  right: -34px;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-24 {
  top: 6rem;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.m-0 {
  margin: 0;
}

.m-56 {
  margin: 14rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.\!h-4 {
  height: 1rem !important;
}

.\!h-6 {
  height: 1.5rem !important;
}

.\!h-8 {
  height: 2rem !important;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[1px\] {
  height: 1px;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.max-h-\[800px\] {
  max-height: 800px;
}

.\!w-32 {
  width: 8rem !important;
}

.\!w-4 {
  width: 1rem !important;
}

.\!w-44 {
  width: 11rem !important;
}

.\!w-6 {
  width: 1.5rem !important;
}

.\!w-8 {
  width: 2rem !important;
}

.\!w-full {
  width: 100% !important;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-22 {
  width: 5.375rem;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-\[216px\] {
  max-width: 216px;
}

.max-w-\[570px\] {
  max-width: 570px;
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.list-outside {
  list-style-position: outside;
}

.list-disc {
  list-style-type: disc;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-stretch {
  justify-content: stretch;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-none {
  border-style: none;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(170 170 170 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(4 7 7 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.\!bg-\[\#652cb3\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(101 44 179 / var(--tw-bg-opacity)) !important;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(230 243 246 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 220 220 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(105 118 131 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(4 7 7 / var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 223 255 / var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(117 98 234 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 235 233 / var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 180 171 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.fill-black {
  fill: #000;
}

.fill-purple-400 {
  fill: #7562ea;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-\[75\%\] {
  padding-top: 75%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.text-\[\#10b981\] {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.text-\[\#652cb3\] {
  --tw-text-opacity: 1;
  color: rgb(101 44 179 / var(--tw-text-opacity));
}

.text-\[\#ff0000\] {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(42 117 199 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(244 244 244 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(4 7 7 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(196 85 68 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(167 47 28 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-70 {
  opacity: .7;
}

.opacity-\[0\.15\] {
  opacity: .15;
}

.shadow-icon-button {
  --tw-shadow: 0px 2px 10px #0003;
  --tw-shadow-colored: 0px 2px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-popover {
  --tw-shadow: 0px 4px 10px #0000004d;
  --tw-shadow-colored: 0px 4px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(76 81 191 / var(--tw-bg-opacity));
}

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 180 171 / var(--tw-bg-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-disabled:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(170 170 170 / var(--tw-bg-opacity));
}

.disabled\:bg-purple-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(158 148 222 / var(--tw-bg-opacity));
}

.disabled\:opacity-75:disabled {
  opacity: .75;
}

.disabled\:hover\:bg-purple-300:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(158 148 222 / var(--tw-bg-opacity));
}

@media (prefers-color-scheme: dark) {
  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(105 118 131 / var(--tw-text-opacity));
  }
}

@media (min-width: 640px) {
  .sm\:-left-6 {
    left: -1.5rem;
  }

  .sm\:-right-6 {
    right: -1.5rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-60 {
    height: 15rem;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-stretch {
    align-items: stretch;
  }

  .sm\:gap-16 {
    gap: 4rem;
  }

  .sm\:gap-2 {
    gap: .5rem;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:bottom-8 {
    bottom: 2rem;
  }

  .md\:right-4 {
    right: 1rem;
  }

  .md\:top-2 {
    top: .5rem;
  }

  .md\:top-2\.5 {
    top: .625rem;
  }

  .md\:\!h-10 {
    height: 2.5rem !important;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-9 {
    height: 2.25rem;
  }

  .md\:h-96 {
    height: 24rem;
  }

  .md\:\!w-10 {
    width: 2.5rem !important;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-84 {
    width: 21.75rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:pl-8 {
    padding-left: 2rem;
  }

  .md\:pr-8 {
    padding-right: 2rem;
  }
}

@media (min-width: 1024px) {
  .lg\:\!h-\[50px\] {
    height: 50px !important;
  }

  .lg\:h-11 {
    height: 2.75rem;
  }

  .lg\:h-136 {
    height: 34rem;
  }

  .lg\:\!w-\[50px\] {
    width: 50px !important;
  }

  .lg\:w-147 {
    width: 36.75rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-84 {
    width: 21.75rem;
  }

  .lg\:w-97 {
    width: 25rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #00f;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --rdp-selected-color: #fff;
  margin: 1em;
}

.rdp-vhidden {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.rdp-button_reset {
  -webkit-appearance: none;
  appearance: none;
  cursor: default;
  color: inherit;
  font: inherit;
  background: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.rdp-button_reset:focus-visible {
  outline: none;
}

.rdp-button {
  border: 2px solid #0000;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: .25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--rdp-background-color);
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
  margin: 0;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
}

.rdp-multiple_months .rdp-caption {
  text-align: center;
  display: block;
  position: relative;
}

.rdp-caption_dropdowns {
  display: inline-flex;
  position: relative;
}

.rdp-caption_label {
  z-index: 1;
  white-space: nowrap;
  color: currentColor;
  font-family: inherit;
  font-size: var(--rdp-caption-font-size);
  border: 2px solid #0000;
  align-items: center;
  margin: 0;
  padding: 0 .25em;
  font-weight: bold;
  display: inline-flex;
  position: relative;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: .25em;
  display: inline-flex;
}

.rdp-dropdown_year, .rdp-dropdown_month {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rdp-dropdown {
  -webkit-appearance: none;
  appearance: none;
  z-index: 2;
  cursor: inherit;
  opacity: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: #0000;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row, .rdp-row {
  height: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  text-align: center;
  height: var(--rdp-cell-size);
  text-transform: uppercase;
  height: 100%;
  padding: 0;
  font-size: .75em;
  font-weight: 700;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: .5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  text-align: center;
  height: 100%;
  padding: 0;
}

.rdp-weeknumber {
  font-size: .75em;
}

.rdp-weeknumber, .rdp-day {
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border: 2px solid #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.rdp-day_selected, .rdp-day_selected:hover {
  color: var(--rdp-selected-color);
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

.rdp-day_selected:focus-visible {
  color: var(--rdp-selected-color);
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

.rdp-day_outside {
  opacity: .5;
}

.rdp-day_selected:focus-visible {
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}

.rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start), .rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
}

.rdp {
  margin: 0;
}

.rdp-nav_icon {
  color: #652cb3;
}

.rdp-day_selected, .rdp-day_selected:hover {
  background-color: #652cb3;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #652cb31a;
}

.markdown-container ul {
  list-style: inside;
}

.markdown-container li {
  margin-left: 16px;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  -o-transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity= 40);
  z-index: 2;
  cursor: pointer;
  background: none;
  border: 0;
  font-size: 32px;
  transition: all .25s ease-in;
  position: absolute;
  top: 20px;
}

.carousel .control-arrow:focus, .carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  content: "";
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  margin: 0 5px;
  display: inline-block;
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity= 0);
  cursor: inherit;
  display: none;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel-root {
  outline: none;
}

.carousel {
  width: 100%;
  position: relative;
}

.carousel * {
  box-sizing: border-box;
}

.carousel img {
  pointer-events: none;
  width: 100%;
  display: inline-block;
}

.carousel .carousel {
  position: relative;
}

.carousel .control-arrow {
  background: none;
  border: 0;
  outline: 0;
  margin-top: -13px;
  font-size: 18px;
  top: 50%;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}

.carousel .thumbs {
  -o-transition: all .15s ease-in;
  white-space: nowrap;
  list-style: none;
  transition: all .15s ease-in;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.carousel .thumb {
  -o-transition: border .15s ease-in;
  white-space: nowrap;
  border: 3px solid #fff;
  margin-right: 6px;
  padding: 2px;
  transition: border .15s ease-in;
  display: inline-block;
  overflow: hidden;
}

.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #333;
}

.carousel .thumb img {
  vertical-align: top;
}

.carousel.carousel-slider {
  margin: 0;
  position: relative;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  color: #fff;
  margin-top: 0;
  padding: 5px;
  font-size: 26px;
  top: 0;
  bottom: 0;
}

.carousel.carousel-slider .control-arrow:hover {
  background: #0003;
}

.carousel .slider-wrapper {
  -o-transition: height .15s ease-in;
  width: 100%;
  margin: auto;
  transition: height .15s ease-in;
  overflow: hidden;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}

.carousel .slider {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.carousel .slider.animated {
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.carousel .slide {
  text-align: center;
  min-width: 100%;
  margin: 0;
  position: relative;
}

.carousel .slide img {
  vertical-align: top;
  border: 0;
  width: 100%;
}

.carousel .slide iframe {
  border: 0;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  display: inline-block;
}

.carousel .slide .legend {
  -o-transition: all .5s ease-in-out;
  color: #fff;
  text-align: center;
  opacity: .25;
  transition: all .5s ease-in-out;
  -o-transition: opacity .35s ease-in-out;
  background: #000;
  border-radius: 10px;
  width: 90%;
  margin-left: -45%;
  padding: 10px;
  font-size: 12px;
  transition: opacity .35s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
}

.carousel .control-dots {
  text-align: center;
  z-index: 1;
  width: 100%;
  margin: 10px 0;
  padding: 0;
  position: absolute;
  bottom: 0;
}

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}

.carousel .control-dots .dot {
  -o-transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity= 30);
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  transition: opacity .25s ease-in;
  display: inline-block;
  box-shadow: 1px 1px 2px #000000e6;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .carousel-status {
  text-shadow: 1px 1px 1px #000000e6;
  color: #fff;
  padding: 5px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.carousel:hover .slide .legend {
  opacity: 1;
}

.carousel-slider {
  overflow: unset !important;
}

.gm-style-mtc, .gm-svpc, .gm-fullscreen-control {
  display: none !important;
}

.gmnoprint {
  right: -2px !important;
  left: unset !important;
  bottom: 4px !important;
  top: unset !important;
}

.gmnoprint > div:first-of-type {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  display: flex;
  box-shadow: none !important;
  background-color: #0000 !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.gmnoprint > div:first-of-type > div {
  display: none;
}

.gmnoprint > div:first-of-type > button {
  box-shadow: 0 2px 10px #0003;
  background-color: #fff !important;
  border-radius: 50% !important;
  width: 2rem !important;
  height: 2rem !important;
}

.gm-style-cc {
  position: unset !important;
}

@media (min-width: 768px) {
  .gmnoprint > div:first-of-type > button {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  .gmnoprint > div:first-of-type {
    gap: .5rem;
  }

  .gmnoprint {
    bottom: 12px !important;
    right: 0 !important;
  }
}

@media (min-width: 1024px) {
  .gmnoprint > div:first-of-type > button {
    width: 3.125rem !important;
    height: 3.125rem !important;
  }

  .gmnoprint > div:first-of-type {
    gap: .75rem;
  }
}

.cards-container::-webkit-scrollbar {
  width: 10px;
}

.cards-container::-webkit-scrollbar-track {
  visibility: hidden;
  background: #f1f1f1;
}

.cards-container:hover::-webkit-scrollbar-track {
  visibility: visible;
}

.cards-container::-webkit-scrollbar-thumb {
  visibility: hidden;
  background: #888;
  border-radius: 30px;
  height: 50px;
}

.cards-container:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.cards-container::-ms-scrollbar {
  width: 14px;
}

.cards-container::-ms-scrollbar-track {
  visibility: hidden;
  background: #f1f1f1;
}

.cards-container:hover::-ms-scrollbar-track {
  visibility: visible;
}

.cards-container::-ms-scrollbar-thumb {
  visibility: hidden;
  background: #888;
  border-radius: 30px;
  height: 30px;
}

.cards-container:hover::-ms-scrollbar-thumb {
  visibility: visible;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}

.App-header {
  color: #fff;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.css.map */
